import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PaymentMethod } from '@payment-app/core/models';

@Injectable({
  providedIn: 'root'
})

export class ExpansionPanelService {
  private notifyPaymentMethod = new Subject<string>();

  constructor() { }

  notifyMe(): Observable<string> {
    return this.notifyPaymentMethod.asObservable();
  }

  sendMessage(paymentMethod: string): void {
    this.notifyPaymentMethod.next(paymentMethod);
  }
}
