import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ComponentsMaterial } from "../../components-material";
import { TextMaskModule } from "angular2-text-mask";

import { PaymentezComponent } from "./paymentez.component";
import { PaymentezDialogComponent } from "./paymentez-dialog/paymentez-dialog.component";
import { PayPseComponent } from "./paymentez-dialog/pay-pse/pay-pse.component";
import { PayDaviplataComponent } from "./paymentez-dialog/pay-daviplata/pay-daviplata.component";
import { PayCreditCardComponent } from "./paymentez-dialog/pay-credit-card/pay-credit-card.component";
import { NumericDirective } from "../../core/directives/numeric.directive";
import { SavedCardModule } from "src/app/commons/saved-card/saved-card.module";
import { PayCheckoutEpaycoComponent } from "./paymentez-dialog/pay-checkout-epayco/pay-checkout-epayco.component";
import { PayDavipuntosComponent } from "./paymentez-dialog/pay-davipuntos/pay-davipuntos.component";
import { ValidateSliderDirective } from './paymentez-dialog/pay-davipuntos/validate-slider.directive';
import { PayMixedComponent } from './paymentez-dialog/pay-mixed/pay-mixed.component';
import { PurchasePaymentMixComponent } from "./paymentez-dialog/pay-mixed/purchase-payment-mix/purchase-payment-mix.component";
import { ApplicationPipesModule } from "src/app/core/pipes/application-pipes.module";
import { PayPuntosColombiaComponent } from './paymentez-dialog/pay-puntos-colombia/pay-puntos-colombia.component';
import { PayNequiComponent } from './paymentez-dialog/pay-nequi/pay-nequi.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import {PayDiraComponent} from './paymentez-dialog/pay-dira/pay-dira.component';
import {DiraIframeComponent} from './paymentez-dialog/pay-dira/components/dira.iframe.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { PayClaroPayComponent } from './paymentez-dialog/pay-claro-pay/pay-claro-pay.component';
import { ConfirmAppDialogComponent } from './paymentez-dialog/pay-claro-pay/confirm-app-dialog/confirm-app-dialog.component';
import { VerifyAppDialogComponent } from './paymentez-dialog/pay-claro-pay/verify-app-dialog/verify-app-dialog.component';
import { NuveiCardComponent } from '@payment-app/components/payment-methods/methods/nuvei-card/nuvei-card.component';
import { NuveiBankTransferComponent } from '@payment-app/components/payment-methods/methods/nuvei-bank-transfer/nuvei-bank-transfer.component';

@NgModule({
  declarations: [
    PaymentezComponent,
    PaymentezDialogComponent,
    PayPseComponent,
    PayCreditCardComponent,
    NumericDirective,
    PayDaviplataComponent,
    PayCheckoutEpaycoComponent,
    PayDavipuntosComponent,
    ValidateSliderDirective,
    PayMixedComponent,
    PurchasePaymentMixComponent,
    PayPuntosColombiaComponent,
    PayNequiComponent,
    PayDiraComponent,
    DiraIframeComponent,
    PayClaroPayComponent,
    ConfirmAppDialogComponent,
    VerifyAppDialogComponent,
    ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsMaterial,
    TextMaskModule,
    SavedCardModule,
    ApplicationPipesModule,
    NgxQRCodeModule,
    RecaptchaV3Module,
    NuveiCardComponent,
    NuveiBankTransferComponent
  ],
  exports: [
    PaymentezComponent,
    PaymentezDialogComponent,
    PayPseComponent,
    PayCreditCardComponent,
    PayDaviplataComponent,
    PayNequiComponent,
    PayClaroPayComponent,
    NumericDirective,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6Ld5YE0mAAAAAEN_A2TLYW9q1BhoQPTUnuk-E5dx",
    },
  ],
})
export class PaymentezModule {}
