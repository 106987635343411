import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AuthModel } from 'src/app/core/models/auth.model';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { PaymentezResponseSdkModel } from 'src/app/core/models/paymentez/paymentez-response-sdk.model';
import { AuditService } from 'src/app/core/services/audit.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { CreditCardPaymentezUtility } from 'src/app/core/utilities/credit-card-paymentez.utility';

@Component({
  selector: "app-purchase-nequi",
  templateUrl: "./purchase-nequi.component.html",
  styleUrls: ["./purchase-nequi.component.scss"],
})
export class PurchaseNequiComponent implements OnInit {
  @Input() paymentRequest: PaymentConfigModel;

  @Input() response: PaymentezResponseSdkModel;

  @Output() imprimir: EventEmitter<any>;

  status: string = "";
  amount: number = 0;
  transactionID: string = "";
  paymentDate: string = "";
  paymentStatus: string = "";
  url_return: string = "";
  authToken: AuthModel;
  backOrDetails: string = "Volver a comercio";
  urlProblem: string = "";

  constructor(
    private sessionStorageService: SessionStorageService,
    private auditService: AuditService
  ) {
    this.imprimir = new EventEmitter();
  }

  ngOnInit(): void {
    this.status = CreditCardPaymentezUtility.getValueResult(
      this.response.transaction.status
    );
    this.amount = this.paymentRequest.data.amount;
    this.transactionID = this.response.transaction.nequi.transactionID;
    this.paymentDate = this.response.transaction.nequi.paymentDate;
    this.authToken = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH
    );
    let auth = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    this.url_return = auth["url_return"];


    if (
      this.response.transaction.status === "RECHAZADO" ||
      this.response.transaction.status === "failure"
    ) {
      this.urlProblem = this.sessionStorageService.getItem<string>(
        SessionStorageService.URL_RETURN_PROBLEM
      );
      this.backOrDetails = this.urlProblem
        ? "MAS DETALLES"
        : this.backOrDetails;
    }
  }

  returnClient() {
    this.validateStatus();
    const request = {
      dev_reference: this.paymentRequest.data.reference,
      client: this.paymentRequest.data.client,
      subclient: this.paymentRequest.data.subclient,
      leave_type: "Salida por Boton",
      payment_status: this.paymentStatus,
      leave_page: true,
    };

    this.auditService
      .saveAudit(request, this.authToken.token)
      .subscribe((res) => {
        window.location.href = this.url_return;
      });
  }

  validateStatus() {
    if (this.response.transaction.status == "failure") {
      this.paymentStatus = "RECHAZADO";
       this.url_return = this.urlProblem ?? this.url_return;
    } else if (this.response.transaction.status == "pending") {
      this.paymentStatus = "PENDIENTE";
    } else {
      this.paymentStatus = "APROBADO";
    }
  }

  printPdf() {
    this.imprimir.emit(true);
  }
}
