import { Component, Input, OnInit, Inject } from '@angular/core';
import { PaymentConfigModel } from "../../../../core/models/payment-config.model";
import { PaymentTransactionModel } from "../../../../core/models/paymentez/payment-transaction.model";
import { AuthModel } from "../../../../core/models/auth.model";
import { SessionStorageService } from "../../../../core/services/session-storage.service";


import { Router } from "@angular/router";
import { PaymentezService } from "../../../../core/services/paymentez.service";
import { UserCardService } from "../../../../core/services/user-card.service";
import { PaymentTransactionService } from "../../../../core/services/payment-transaction.service";
import { LoadingScreenService } from "../../../../core/services/loading-screen.service";

import { TooltipPosition } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';

import { DOCUMENT } from "@angular/common";
import { AuthService } from 'src/app/core/services/auth.service';
import * as CryptoJS from 'crypto-js';
import { PaymentMethodService } from '../../../../core/services/payment-method.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentezComponent } from '../../paymentez.component';
import { DatalayerService } from 'src/app/core/services/datalayer.service';

import { EpaycoService } from 'src/app/core/services/epayco.service';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import {PostMessageService} from "../../../../core/services/post-message.service";


declare var window: any;
var nameCategory: any;
var description: any;
@Component({
  selector: 'app-pay-pse',
  templateUrl: './pay-pse.component.html',
  styleUrls: ['./pay-pse.component.scss']
})
export class PayPseComponent implements OnInit {

  @Input() paymentRequest: PaymentConfigModel;
  service: any;

  documentType: string;
  documentNumber: string;
  personType: string;
  bank: string;
  message: any;
  urlReturnPSE: any;
  description = null;
  loading = false;
  activate_mixed: boolean = false;

  documents: any[] = [
    { code: "CC", name: "Cédula de Ciudadania" },
    { code: "CE", name: "Cédula de Extranjeria" },
    { code: "PPN", name: "Cédula de Pasaporte" },
    { code: "NIT", name: "Número de identificación tributario" },
    { code: "TI", name: "Tarjeta de identidad" }
  ]
  validatorRetentions: boolean = false;
  public auditError;
  constructor(
    private _router: Router,
    private _paymentezService: PaymentezService,
    private paymentTransactionService: PaymentTransactionService,
    private sessionStorageService: SessionStorageService,
    private loadingScreenService: LoadingScreenService,
    private userCardService: UserCardService,
    private paymentMethodService: PaymentMethodService,
    public dialogRef: MatDialogRef<PaymentezComponent>,
    private datalayerService: DatalayerService,
    @Inject(DOCUMENT) document: any,
    private epaycoService: EpaycoService,
    private changeStatusService: ChangeStatusService,
    private postMessageService: PostMessageService
  ) {
    this.urlReturnPSE = document.location.origin + '/pagos/respuesta';
  }

  ngOnInit() {
    this.description = this.paymentRequest.data.description;
    if(this.paymentRequest.data){
      this.documentType = this.paymentRequest.data.id_type;
      this.documentNumber = this.paymentRequest.data.id;
      this.personType = this.paymentRequest.data.type_user;
      this.paymentRequest.data.amount = this.paymentRequest.aux_init_amount;
    }
    this.service = this.paymentMethodService.gateway(this.paymentRequest.gateway_active);
    this.service.message.subscribe(message => {
      this.message = message.message;
      this.loadingScreenService.stopLoading();
    });

    this.epaycoService.activateMixed.subscribe(mixed_activate => {
      this.activate_mixed = mixed_activate;
  })
    this.epaycoService.validatedRetentions.subscribe(retentions =>{
      if (this.paymentRequest.retentions && !this.paymentRequest.retentions.message){
        this.validatorRetentions = retentions;
      }
      if (this.paymentRequest.retentions && this.paymentRequest.retentions.message) {
        this.paymentRequest.retentions = null;
      }
    })
  }

  async transferBank() {


    this.message = null;
    this.loading = true;
    this.addToDataLayerCheckoutEvent('PSE-'+this.bank, this.paymentRequest);
    this.datalayerService.checkoutEvent("2", this.paymentRequest);

    if(this.paymentRequest.productsAnalyticsObject){
      this.sessionStorageService.setItem(SessionStorageService.PRODUCTSANALYTICS, this.paymentRequest.productsAnalyticsObject);
    }

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.retentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    if (this.paymentRequest.name == 'ciencuadras') {
      this.datalayerService.AddInfoDataLayerPaymentButton(textButton);
    }
    if (this.paymentRequest.name == 'doctoraki') {
      this.datalayerService.addInfoDataLayerPurchaseResume(textButton, this.paymentRequest.name, 'PSE')
    }

    if (!this.validateEmptyField()) {
      const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const request ={
        type: this.documentType,
        fiscal_number: this.documentNumber,
      }
      this.message = "Datos incompletos. Por favor diligenciar todos los campos.";
      await this.changeStatusService.changeStatus(auth, this.paymentRequest, 'PSE', request);
      const token = 'Bearer ' + auth.token;
      this.loading = false;
      return;
    }
     this.postMessageService.send(true);

    setTimeout(() => { this.loading = false; }, 1500);

    this.loadingScreenService.startLoading();

    let _wordsReference = CryptoJS.enc.Utf8.parse(this.paymentRequest.name + ":" + this.paymentRequest.data.reference);
    let base64Reference = CryptoJS.enc.Base64.stringify(_wordsReference);

    let _request = {
      fiscal_number: this.documentNumber,
      type: this.personType,
      type_fis_number: this.documentType,
      urlReturnPSE: this.urlReturnPSE,
      base64Reference: base64Reference,
      bank: this.bank,
      activate_mixed: this.activate_mixed ? this.activate_mixed : null,
    };
    let auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);

    this.service.transactionPSE(auth.token, _request, this.paymentRequest, this.dialogRef);

    this.addInfoDataLayerPay()
  }

  public addInfoDataLayerPay(){
    nameCategory = this.paymentRequest.data.client
    description = this.paymentRequest.data.description
    window.dataLayer.push({
      eventCategory: nameCategory + " - Intención de pago",
      eventAction: 'Pago con PSE',
      eventLabel: description,
      eventValue: '',
      event:'eventClick'
    });

  }

  public validateEmptyField(){
    return this.personType.length && this.documentType.length && this.documentNumber.length && this.bank != undefined
  }

  addToDataLayerCheckoutEvent(method: string, paymentRequest){
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

}
