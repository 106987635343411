import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class DavipuntosMixedPurchaseEncryptService {

  encryptCipherData(input){
    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify({ cipher: input }),
      key,
      {
        mode: CryptoJS.mode.CTR,
        iv,
        padding: CryptoJS.pad.NoPadding
      }
    );
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  encryptStringData(input){
    const key = CryptoJS.enc.Hex.parse(environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(input),
      key,
      {
        mode: CryptoJS.mode.CTR,
        iv,
        padding: CryptoJS.pad.NoPadding
      }
    );
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  decryptData(cipherText: string, keyDecrypt?: string) {
    const key = CryptoJS.enc.Hex.parse(keyDecrypt ?? environment.secretTimeKey);
    const iv = CryptoJS.enc.Hex.parse(environment.secretTimeIv);

    const decrypted = CryptoJS.AES.decrypt(this.replaceCharacter(cipherText), key, {
      mode: CryptoJS.mode.CTR,
      iv,
      padding: CryptoJS.pad.NoPadding
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }

  private replaceCharacter(cipherText: string) {
    return cipherText.replace(/(\-[0-9][0-9]\-)/g, (match: string) =>
      String.fromCharCode(parseInt(match.replace(/\-/g, ''), 10))
    );
  }
}
