import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { TransactionDataService } from '@payment-app/core/services/transaction-data';
import { WarningMessageService } from '@payment-app/components/warning-message/warning-message.service';
import { PaymentDataService } from '@payment-app/screen/payment-process/components/payment-data/payment-data.service';
import { TokenizationFormService } from './tokenization-form.service';
import { NuveiSuccessResponse, NuveiErrorResponse } from './interfaces';
import { TokenizationPaymentGatewayService } from './tokenization-payment-gateway.service';
import { PaymentIconComponent } from '@payment-app/components/icons/payment-icon';
import { FormPayment } from '@payment-app/core/models';
import { ExpansionPanelService } from '@payment-app/components/payment-methods/expansion-panel/expansion-panel.service';

type NuveiResponse = NuveiSuccessResponse | NuveiErrorResponse;

@Component({
  selector: 'tokenization-form',
  templateUrl: './tokenization-form.component.html',
  styleUrls: ['./tokenization-form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    CommonModule,
    MatSelectModule,
    MatIconModule,
    PaymentIconComponent,
  ],
})
export class TokenizationFormComponent implements OnInit {
  @Input() readonly isCreditCard = false;
  readonly #tokenizationFormService = inject(TokenizationFormService);
  readonly #paymentDataService = inject(PaymentDataService);
  readonly #transactionDataService = inject(TransactionDataService);
  readonly #warningMessageService = inject(WarningMessageService);
  readonly #paymentGatewayService = inject(TokenizationPaymentGatewayService);
  readonly customerData = this.#transactionDataService.transactionData.customerData;
  readonly transaction = this.#transactionDataService.transactionData.transaction;
  readonly appCode =
    this.#transactionDataService.transactionData.portal.configuration.credentials.nuvei
      .code;
  isCard = true;
  #expansionPanelService = inject(ExpansionPanelService);
  #paymentGateway!: any;
  errorMessage!: string;
  installmentsOptions = Array.from({ length: 36 }, (_, i) => i + 1);
  installments = new FormControl<number | null>(null, [Validators.required]);
  isInstallmentSet = false;

  ngOnInit(): void {
    this.#tokenizationFormService.isLoaded.subscribe((isLoaded) => {
      if (isLoaded) {
        this.#paymentGateway = this.#tokenizationFormService.getPaymentGateway();
      }
    });

    this.#expansionPanelService.notifyMe().subscribe(paymentMethod => {
      if (
        !(paymentMethod !== FormPayment.CREDIT_CARD &&
          paymentMethod !== FormPayment.DEBIT_CARD &&
          paymentMethod !== FormPayment.BANK_TRANSFER &&
          paymentMethod !== FormPayment.DEBIT_CREDIT_CARD &&
          paymentMethod !== FormPayment.TOKENIZED_CARDS)
      ) {
        this.generateTokenize();
        this.#paymentDataService.callToAction(() => {
          if (!this.isInstallmentSet && this.isCreditCard) {
            this.installments.markAsTouched();
            return this.notCompletedFormCallback('número de cuotas');
          }
          if (this.#paymentGateway) {
            this.#paymentGateway.tokenize();
          }
        });
      }
    });

    this.installments.valueChanges.subscribe((data) => {
      this.isInstallmentSet = !!data;
    });
  }

  private generateTokenize() {
    this.#paymentGateway.generate_tokenize(
      this.getTokenizeData(),
      '#tokenize_form',
      this.responseCallback,
      this.notCompletedFormCallback,
    );
  }

  private getTokenizeData() {
    return {
      locale: 'es',
      user: {
        id: this.customerData.idValue,
        email: this.customerData.email,
      },
      configuration: {
        default_country: 'COL',
      },
    };
  }

  private responseCallback = (response: NuveiResponse) => {
    if (response.hasOwnProperty('error')) {
      response = response as NuveiErrorResponse;
      const token = this.getTokenByErrorType(response.error.type);
      if (token) {
        this.debitWithToken(token);
      }

      this.generateTokenize();
    } else {
      response = response as NuveiSuccessResponse;
      if (response.card.status === 'rejected') {
        this.generateTokenize();

        this.installments.reset();

        this.#warningMessageService.alert(
          'Error',
          `Tarjeta rechazada (${response.card.message})`,
          'error',
        );
      } else {
        const token = response.token ?? response.card.token;
        this.#paymentGatewayService.saveToken(this.customerData.idValue, this.appCode, {
          bin: response.card.bin ?? response.bin,
          expiry_month: response.card.expiry_month ?? response.expiry_month,
          expiry_year: response.card.expiry_year ?? response.expiry_year,
          number: response.card.number ?? response.number,
          status: response.card.status ?? response.status,
          message: response.card.message ?? response.message,
          transaction_reference:
            response.card.transaction_reference ?? response.transaction_reference,
          isCreditCard: this.isCreditCard,
          token: response.card.token ?? response.token,
          type: response.card.type ?? response.type,
        });
        this.debitWithToken(token);
      }
    }
  };

  private notCompletedFormCallback = (message: string) => {
    this.#warningMessageService.alert(
      'Error',
      `Formulario no finalizado. Por favor, rellene los datos requeridos (${message})`,
      'warning',
    );
  };

  private getTokenByErrorType(errorType: string): string | null {
    if (errorType.includes('Card already added:')) {
      return errorType.split(':')[1].trim();
    }

    return null;
  }

  private debitWithToken(token: string) {
    this.#paymentGatewayService
      .debitWithToken({
        userId: this.customerData.idValue,
        email: this.customerData.email,
        amount: this.transaction.amount,
        description: this.transaction.description,
        reference: this.transaction.reference,
        vat: this.transaction.vat,
        tokenCard: token,
        installments: this.isCreditCard ? this.installments.value : 1,
      })
      .subscribe({
        next: (_) => (window.location.href = this.transaction.responseUrl),
        error: (_) => (window.location.href = this.transaction.responseUrl),
      });
  }
}
