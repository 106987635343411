import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  #callback = new BehaviorSubject(null);
  #buttonTitle =  new Subject<string>();

  callToAction(callback: () => void, buttonTitle: string = 'Pagar') {
    this.#buttonTitle.next(buttonTitle);
    this.#callback.next(callback);
  }

  get callback(): BehaviorSubject<() => void> {
    return this.#callback;
  }

  get buttonTitle():Observable<string> {
    return this.#buttonTitle;
  }
}
