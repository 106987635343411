import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSliderChange } from '@angular/material/slider';
import { Router } from '@angular/router';

import { AuthModel } from 'src/app/core/models/auth.model';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import {
  CreditCards,
  Data,
  DavipuntosService,
  PricePoints2,
  Product,
} from 'src/app/core/services/davipuntos.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { DavipuntosCredentials } from 'src/app/payment/payment-davipuntos/payment-davipuntos.component';
import { LoadingScreenService } from '@core/services/loading-screen.service';

@Component({
  selector: 'app-pay-davipuntos',
  templateUrl: './pay-davipuntos.component.html',
  styleUrls: ['./pay-davipuntos.component.scss'],
})
export class PayDavipuntosComponent implements OnInit {
  @Input() paymentRequest: PaymentConfigModel;
  @ViewChild('maxPointsInput') maxPointsInput: ElementRef;
  davipuntosCredentials: DavipuntosCredentials;
  products: Product[] = [];
  creditsCard: CreditCards;
  selectedCard: Product;
  pricePoints: PricePoints2;
  pricePoint = 0;
  pointsSelected: number;
  isLoading = false;
  validateMakePayment = false;
  sliderMax: number;
  sliderMaxValue: number;
  sliderValue = 0;
  installments: number[];
  installmentSelected: number;
  totalAmountPayCreditCard = 0;
  errorMessage: string;
  infoMessage =
    'Serás redirigido a la plataforma de tu banco para iniciar sesión en tu cuenta y continuar con el proceso';
  login = false;
  hasPricePoints = false;
  dataDecrypted: Data;
  auditError: any;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private loadingScreenService: LoadingScreenService,
    private changeStatusService: ChangeStatusService,
    private davipuntosService: DavipuntosService,
    private authService: AuthService,
    private datalayerService: DatalayerService,
  ) {
    this.davipuntosCredentials = this.sessionStorageService.getItem(
      SessionStorageService.DAVIPUNTOS_CREDENTIALS,
    );

    this.installments = Array(36)
      .fill(1)
      .map((x, i) => i + 1);
  }

  ngOnInit(): void {
    if (this.davipuntosCredentials) {
      this.infoMessage = null;
      this.getData(this.davipuntosCredentials);
    }

    //  this.dataMock();
  }

  async launchLogin(): Promise<void> {
    try {
      this.loadingScreenService.startLoading();

      const response = await this.davipuntosService
        .davipuntosLogin(this.paymentRequest.data.reference)
        .toPromise();

      if (response.success) {
        window.open(response.data.urlOauth, '_self');

        this.loadingScreenService.stopLoading();
      } else {
        if (response.data.errorCode) {
          this.errorMessage = response.data.errorMessage;

          this.auditAttemptedStatusError(this.errorMessage);
        }

        this.loadingScreenService.stopLoading();
      }
    } catch (error) {
      this.errorMessage = 'Se ha presentado un error con esta solicitud, error 500';
      this.auditAttemptedStatusError(this.errorMessage);

      this.loadingScreenService.stopLoading();
      console.error(error);
    }
  }

  async makePayment(): Promise<void> {
    // console.log('TOKEN', this.davipuntosCredentials.token);
    try {
      if (this.paymentRequest.name === 'doctoraki') {
        this.datalayerService.addInfoDataLayerPurchaseResume(
          'PAGAR ' + this.paymentRequest.data.amount,
          this.paymentRequest.name,
          'DAVIPUNTOS',
        );
      }

      if (this.validatePointsAgainstTotalAmount()) {
        await this.showErrorMessage(
          'No cuentas con la cantidad suficiente de puntos para realizar esta transacción',
        );
        return;
      }

      const success = await this.authService.getTimezone().toPromise();

      this.loadingScreenService.startLoading();

      const response = await this.davipuntosService
        .makePayment(this.paymentRequestObjet(), success)
        .toPromise();

      this.loadingScreenService.stopLoading();

      if (response.success) {
        const responseDavipuntos = response.data;

        const franchiseProduct = this.products.find(
          (product) => product.idProduct === response.data.selectedCard,
        );

        this.paymentRequest.data.transactionId = responseDavipuntos.transactionId;

        await this.router.navigate(['/pagos/respuesta'], {
          state: {
            paymentRequest: this.paymentRequest,
            response: {
              transaction: {
                status: responseDavipuntos.status,
                davipuntos: {
                  points: responseDavipuntos.points ? responseDavipuntos.points : null,
                  selectedCard: franchiseProduct ? franchiseProduct.franchise : null,
                  installmentSelected: responseDavipuntos.installmentSelected
                    ? responseDavipuntos.installmentSelected
                    : null,
                  totalAmountPayCreditCard: responseDavipuntos.totalAmountPayCreditCard
                    ? responseDavipuntos.totalAmountPayCreditCard
                    : null,
                  subCodeProduct: franchiseProduct ? franchiseProduct.reference : null,
                },
              },
            },
          },
        });
      } else {
        if (response.data.errorCode) {
          this.isLoading = false;
          await this.showErrorMessage(response.data.errorMessage, 5000);
        }
      }
    } catch (error) {
      await this.router.navigate(['/pagos/mensaje'], {
        state: {
          message: 'Se ha presentado un error con esta solicitud, error 500',
        },
      });
    }
  }

  async logOut(token: string): Promise<void> {
    try {
      await this.davipuntosService.logOut(token).toPromise();
    } catch (error) {
      this.errorMessage = 'Error cerrando sesión';
      this.auditAttemptedStatusError(this.errorMessage);
      console.error(error);
    }
  }

  addPoints() {
    if (this.sliderValue < this.sliderMaxValue) {
      this.sliderValue++;
    }

    this.setTotalAmountPayCreditCard();
  }

  subtractPoints() {
    if (this.sliderValue > 0) {
      this.sliderValue--;
    }

    this.setTotalAmountPayCreditCard();
  }

  getPoints(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.sliderValue.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  slideIt(matSliderChange: MatSliderChange) {
    if (matSliderChange.value > this.sliderMaxValue) {
      matSliderChange.source.value = this.sliderMaxValue;
      this.sliderValue = this.sliderMaxValue;
    }

    this.setTotalAmountPayCreditCard();
  }

  inputIt(data: number) {
    if (data >= this.sliderMaxValue) {
      this.maxPointsInput.nativeElement.value = this.sliderMaxValue;
      this.sliderValue = this.sliderMaxValue;
    }

    this.setTotalAmountPayCreditCard();
  }

  private async getData(davipuntosCredentials: DavipuntosCredentials) {
    try {
      this.isLoading = true;

      const responseData = await this.davipuntosService
        .getData(
          davipuntosCredentials.rstate,
          davipuntosCredentials.confirmation,
          davipuntosCredentials.username,
          this.paymentRequest.data.reference,
        )
        .toPromise();

      if (responseData.success) {
        this.dataDecrypted = this.authService.decryptDataPlain(responseData.data);
        if (
          this.dataDecrypted.creditCards.errorCode &&
          this.dataDecrypted.pricePoints.errorCode
        ) {
          await this.showErrorMessage(
            'Lo sentimos en estos momentos nuestros servicios no se encuentran disponibles, por favor intente mas tarde',
            5000,
          );

          this.sessionStorageService.removeItem(
            SessionStorageService.DAVIPUNTOS_CREDENTIALS,
          );

          this.davipuntosCredentials = null;
        }

        if (this.dataDecrypted.token) {
          this.davipuntosCredentials.token = this.dataDecrypted.token;

          this.sessionStorageService.setItem(
            SessionStorageService.DAVIPUNTOS_CREDENTIALS,
            this.davipuntosCredentials,
          );
        }

        if (this.dataDecrypted.creditCards.errorCode) {
          await this.showErrorMessage(
            `Credit Card Error: ${this.dataDecrypted.creditCards.errorMessage}`,
            4000,
          );
        } else {
          this.products = this.dataDecrypted.creditCards.Products.map(
            (product) => product.Product,
          );
        }

        if (this.dataDecrypted.pricePoints.errorCode) {
          this.totalAmountPayCreditCard = this.paymentRequest.data.amount;
          await this.showErrorMessage(
            `Price Points Error: ${this.dataDecrypted.pricePoints.errorMessage}`,
            4000,
          );
        } else {
          this.pricePoints = this.dataDecrypted.pricePoints;
          if (
            this.dataDecrypted.pricePoints.contract &&
            this.dataDecrypted.pricePoints.contract.errorCode
          ) {
            this.totalAmountPayCreditCard = this.paymentRequest.data.amount;
            await this.showErrorMessage(
              `Contract Error: ${this.dataDecrypted.pricePoints.contract.errorMessage}`,
              4000,
            );
          } else {
            this.setPaymentVariables(this.pricePoints, this.paymentRequest.data.amount);
          }
        }
      } else {
        this.sessionStorageService.removeItem(
          SessionStorageService.DAVIPUNTOS_CREDENTIALS,
        );
        this.davipuntosCredentials = null;

        // tslint:disable:no-string-literal
        await this.showErrorMessage(responseData['err']);
        setTimeout(() => {
          this.showErrorMessage('Intente de nuevo por favor');
        }, 3000);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;

      console.error(error);
    }
  }

  private setPaymentVariables(points: PricePoints2, amount: number) {
    this.hasPricePoints = true;
    // get number of points according to amount.
    this.sliderMax = Math.ceil(amount / points.pointsConversionRate.price);

    this.pointsSelected = this.sliderMax;

    this.pricePoint = points.pointsConversionRate.price;

    const totalPointsClient = points.points.total;

    this.sliderValue =
      totalPointsClient >= this.sliderMax ? this.sliderMax : totalPointsClient;

    this.sliderMaxValue = this.sliderValue;

    this.setTotalAmountPayCreditCard();
  }

  private paymentRequestObjet() {
    const pointsValue = parseInt(this.sliderValue.toString(), 10);

    if (pointsValue && this.selectedCard) {
      const franchiseProduct = this.products.find(
        (product) => product.idProduct === this.selectedCard.idProduct,
      );

      return {
        pointsInfo: {
          pointsQty: parseInt(this.sliderValue.toString(), 10),
          ivaTax: 0,
        },
        creditCardInfo: {
          creditCardId: this.selectedCard.idProduct,
          amount: this.totalAmountPayCreditCard,
          installments: this.installmentSelected,
          ivaTax: 0,
          comsumptionTax: 0,
          taxBase: 0,
          tip: 0,
        },
        token: this.davipuntosCredentials.token,
        transaction: {
          amount: this.paymentRequest.data.amount,
          client: this.paymentRequest.name,
          devReference: this.paymentRequest.data.reference,
          payLink: this.paymentRequest.data.pay_link,
          subclient: this.paymentRequest.data.subclient,
          email: this.paymentRequest.data.email,
          description: this.paymentRequest.data.description,
          franchise: franchiseProduct ? franchiseProduct : null,
        },
      };
    }

    if (pointsValue && !this.selectedCard) {
      return {
        transaction: {
          amount: this.paymentRequest.data.amount,
          client: this.paymentRequest.name,
          devReference: this.paymentRequest.data.reference,
          payLink: this.paymentRequest.data.pay_link,
          subclient: this.paymentRequest.data.subclient,
        },
        pointsInfo: {
          pointsQty: parseInt(this.sliderValue.toString(), 10),
          ivaTax: 0,
        },
        token: this.davipuntosCredentials.token,
      };
    }

    if (!pointsValue && this.selectedCard) {
      return {
        transaction: {
          amount: this.paymentRequest.data.amount,
          client: this.paymentRequest.name,
          devReference: this.paymentRequest.data.reference,
          payLink: this.paymentRequest.data.pay_link,
          subclient: this.paymentRequest.data.subclient,
        },
        mixedPurchase: {
          creditCardInfo: {
            creditCardId: this.selectedCard.idProduct,
            amount: this.totalAmountPayCreditCard,
            installments: this.installmentSelected,
            ivaTax: 0,
            comsumptionTax: 0,
            taxBase: 0,
            tip: 0,
          },
        },
        token: this.davipuntosCredentials.token,
      };
    }
  }

  private setTotalAmountPayCreditCard() {
    const saveValue =
      this.paymentRequest.data.amount - this.sliderValue * this.pricePoint;

    this.totalAmountPayCreditCard = saveValue < 0 ? 0 : saveValue;

    if (!this.totalAmountPayCreditCard) {
      this.selectedCard = null;
    }
  }

  onSubmit(formulario: NgForm) {
    if (formulario.valid) {
      if (this.validateMakePayment) {
        this.makePayment().then();
      } else {
        this.launchLogin().then();
      }
    } else {
      Object.values(formulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  public async launchLoginValidate() {
    this.login = true; // esto va en launchLoginValidate
    await this.auditAttemptedStatus();
    setTimeout(() => {
      if (this.errorMessage != null || !this.errorMessage?.length) {
        this.errorMessage = null;
      }
    }, 5000);
    this.validateMakePayment = false;
  }

  public makePaymentValidate(): void {
    this.login = false;
    this.auditAttemptedStatus().then();
    this.validateMakePayment = true;
  }

  public async auditAttemptedStatus() {
    const auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH,
    );
    const body = {
      dev_reference: this.paymentRequest.data.reference
        ? this.paymentRequest.data.reference
        : null,
    };
    await this.changeStatusService.changeStatus(
      auth,
      this.paymentRequest,
      'DAVIPUNTOS_Y_O_CREDITO',
      body,
      this.login,
    );
  }

  public auditAttemptedStatusError(message: string) {
    const auth = this.sessionStorageService.getItem<AuthModel>(
      SessionStorageService.AUTH,
    );
    const audit = {
      reference: this.paymentRequest.data.reference,
      customer_email: this.paymentRequest.data.email,
      client: this.paymentRequest.name,
      payment_reference: this.paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: message,
    };
    this.auditError = {
      client: this.paymentRequest.name,
      reference: this.paymentRequest.data.reference,
      payment_reference: this.paymentRequest.data.payment_reference,
    };
    this.changeStatusService.auditErrorStatusAttempted(
      audit,
      this.auditError,
      auth.token,
    );
  }

  addToDataLayerCheckoutEvent(method: string, paymentRequest: any) {
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

  private validatePointsAgainstTotalAmount(): boolean {
    return (
      this.sliderValue * this.pricePoint < this.paymentRequest.data.amount &&
      !this.products.length
    );
  }

  async showErrorMessage(message: string, timeMilliseconds = 3000) {
    this.errorMessage = message;
    setTimeout(() => {
      if (this.errorMessage != null) {
        this.errorMessage = null;
      }
    }, timeMilliseconds);
    this.auditAttemptedStatusError(this.errorMessage);
  }

  private dataMock() {
    this.creditsCard = {
      //  Products: [
      //    {
      //      Product: {
      //        idProduct:
      //          "388a44a81281087f67a85479c52d25bf48c54820c83eef22e34961ff57463eb5",
      //        reference: "5128",
      //        bin: "360477",
      //        alias: "Tarjeta de credito",
      //        franchise: "DINERS DAVIVIENDA",
      //        state: "active",
      //        codeProduct: "0120",
      //        subCodeProduct: "8012",
      //      }
      //    },
      //    {
      //      Product: {
      //        idProduct:
      //          "3b9a5e3c9a41199aba43fea83942b9c8f3281819bf78d307fcdc1151ba909ac9",
      //        reference: "4861",
      //        bin: "441080",
      //        alias: "Tarjeta de credito",
      //        franchise: "VISA",
      //        state: "active",
      //        codeProduct: "0120",
      //        subCodeProduct: "3148",
      //      }
      //    }
      //  ],
      errorCode: '40',
      errorMessage: 'Error en la transaccion',
    };

    //  this.products = this.creditsCard.Products.map(
    //    (product) => product.Product
    //  );
    /*
    this.pricePoints = {
      contract: {
        points: "000000001",
        pricePoints: "000000020",
        minimumPointsToRedemption: "000000005",
        //  errorCode: "500",
        //  errorMessage: "Internal Server Error",
      },
      client: {
        points: "4532458",
        //  points: "0",
        nextPointsToExpire: "000010905",
        datePointsToExpire: "2021-10-31",
        name: "MANUELA ORTEGA TORO",
        numberDocument: "99091705636",
        typeDocument: "04",
      },
      // errorCode: "500",
      // errorMessage: "Internal Server Error",
    };
    */
    this.dataDecrypted = {
      creditCards: this.creditsCard,
      pricePoints: this.pricePoints,
    };

    this.setPaymentVariables(this.pricePoints, this.paymentRequest.data.amount);
  }
}
